import React, { FC } from 'react';
import styled from 'styled-components';

interface LoadingBarProps {
  progress: number;
}

const Container = styled.div`
  width: 100%;
  height: 0.75rem;
  max-width: 28.75rem;
  background-color: #dbf0f1;
  border-radius: 1rem;
  display: flex;
  align-items: center;
`;

const Progress = styled.div<{ width: number }>`
  width: ${({ width }) => (width > 100 ? 100 : width)}%;
  height: 0.75rem;
  transition: 5s ease;
  background-color: #7bb2c6;
  border-radius: 1rem;
`;

const LoadingBar: FC<LoadingBarProps> = ({ progress, ...props }) => (
  <Container {...props}>
    <Progress width={progress} />
  </Container>
);

export default LoadingBar;
